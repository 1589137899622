import { forwardRef } from "react";
import { FaCheck } from "react-icons/fa";

export interface TextFieldProps
  extends React.PropsWithoutRef<JSX.IntrinsicElements["input"]> {
  error?: string | undefined;
  nolabel?: boolean
  label?: string;
  noMargin?: boolean;
}

export default forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  const { label, name, onChange, nolabel, error, onBlur, type, noMargin, ...rest } = props;

  let className =
    "appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline required:border-red-500";

  if (type === "checkbox")
    className =
      "appearance-none cursor-pointer border rounded h-7 w-7 text-gray-700  bg-white leading-tight focus:outline-none focus:shadow-outline";

  className = `${className} ${error ? 'border-red-600' : 'border-title'}`;

  return (
    <div className={!noMargin ? 'mb-4' : ''}>
      <label
        className="block text-title text-sm mb-4"
        htmlFor={name}
      >
        {!nolabel && label}
        <div className="relative mt-3">
          <input
            className={className}
            name={name}
            id={name}
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            {...rest}
          />
          {type === "checkbox" && <FaCheck className="cursor-pointer absolute text-lg left-1 top-1 opacity-0 checkIcon" />}
        </div>
      </label>

      {error && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
  );
});
