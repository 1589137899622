/* eslint-disable jsx-a11y/no-redundant-roles */
import { CheckIcon } from "@heroicons/react/20/solid";

const steps = [
  {
    name: "Your challenges",
    description: "Set your current challenges",
  },
  {
    name: "Your goals",
    description: "Set a goal for each challenge",
  },
  {
    name: "Your account",
    description: "Who are you ?",
    lifeOnly: true,
  },
  {
    name: "Your platform is ready",
    description: "Start improving your KPIs",
  },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

function CircleIcon({ type }: any) {
  return (
    <span className="flex h-9 items-center" aria-hidden="true">
      {type === "before" && (
        <span className="relative z-10 flex h-4 w-4 lg:h-8 lg:w-8 items-center justify-center rounded-full bg-indigo-600">
          <CheckIcon className="h-2 w-2 lg:h-5 lg:w-5 text-white" aria-hidden="true" />
        </span>
      )}

      {type === "current" && (
        <span className="relative z-10 flex h-4 w-4 lg:h-8 lg:w-8 items-center justify-center rounded-full border lg:border-2 border-indigo-600 bg-white">
          <span className="h-1 w-1 lg:h-2.5 lg:w-2.5 rounded-full bg-indigo-600 animate-pulse" />
        </span>
      )}

      {type === "after" && (
        <span className="relative z-10 flex h-4 w-4 lg:h-8 lg:w-8 items-center justify-center rounded-full border focus-within:lg:border-2 border-gray-300 bg-white">
          <span className="h-2 w-2 lg:h-5 lg:w-5 rounded-full bg-transparent " />
        </span>
      )}
    </span>
  );
}

function Progression({ currentStep }: { currentStep: number }) {
  return (
    <nav aria-label="Progress">
      <ol role="list" className="overflow-hidden flex justify-between lg:block">
        {steps.map((step, stepIdx) => {
          if (step.lifeOnly && process.env.REACT_APP_PLATFORM !== 'life') return undefined;
          return (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== steps.length - 1 ? "pb-10" : "",
              "relative"
            )}
          >
            {stepIdx < currentStep ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full lg:w-0.5 bg-indigo-600"
                    aria-hidden="true"
                  />
                ) : null}
                <span className="group relative flex items-start">
                  <CircleIcon type="before" />
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-xs lg:text-sm font-medium">
                      {step.name}
                    </span>
                    <span className="text-sm text-gray-500  hidden lg:block">
                      {step.description}
                    </span>
                  </span>
                </span>
              </>
            ) : stepIdx === currentStep ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full lg:w-0.5 bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <span
                  className="group relative flex items-start"
                  aria-current="step"
                >
                  <CircleIcon type="current" />
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-xs lg:text-sm font-medium text-indigo-600">
                      {step.name}
                    </span>
                    <span className="text-sm text-gray-500 hidden lg:block">
                      {step.description}
                    </span>
                  </span>
                </span>
              </>
            ) : (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full lg:w-0.5 bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <span className="group relative flex items-start">
                  <CircleIcon type="after" />

                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-xs lg:text-sm font-medium text-gray-500">
                      {step.name}
                    </span>
                    <span className="text-sm text-gray-500  hidden lg:block">
                      {step.description}
                    </span>
                  </span>
                </span>
              </>
            )}
          </li>
        );
      })}
      </ol>
    </nav>
  );
}

export default Progression;
