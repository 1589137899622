import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function RedirectToLife() {
  const navigate = useNavigate();

  useEffect(() => {
    // if (process.env.REACT_APP_PLATFORM === 'life') return navigate('/account');
    navigate('/challenges');
  }, []);

  return null;
}

export default RedirectToLife;
