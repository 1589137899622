import { gql, useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import FormInput from "../components/FormInput";
import Progression from "../components/Progression";

type FormValues = {
  name: string;
  email: string;
};

const USER_EXISTS_QUERY = gql`
  query checkUserExists($email: String) {
    checkUserExists(email: $email)
  }
`;

const SETUP_MUTATION = gql`
  mutation SetupInstance($challenges: [ChallengeInput!], $name: String!, $companyName: String!, $email: String!, $life: Boolean) {
    setupInstance(challenges: $challenges, name: $name, email: $email, companyName: $companyName, life: $life) {
      code
      created
      id
      message
      token
      slug
      status {
        error
        success
        user {
          id
          email
        }
      }
      success
    }
  }
`;

function UserInfoPage() {
  const { refetch: checkUserExists } = useQuery(USER_EXISTS_QUERY);
  const [setupMutation, { loading }] = useMutation(SETUP_MUTATION);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: {}, mode: "onChange" });

  // Check if this user already exists in our DB
  async function checkUserAvailable(email: string) {
    if (!email) return undefined;
    const res = await checkUserExists({ email });
    const exists = res?.data?.checkUserExists;
    return exists
      ? "This user already exists in Yood. Please use another email address."
      : undefined;
  }

  async function onSubmit(formData: FormValues) {
    const data = JSON.parse(sessionStorage.getItem("formValue") || "{}");
    data.user = formData;
    sessionStorage.setItem("formValue", JSON.stringify(data));

    // return console.log(data);

    if (process.env.REACT_APP_PLATFORM === 'life') {
      try {
        const setupResult = await setupMutation({
          variables: {
            challenges: data?.challenges?.map((c:any) => ({
              id: c.id,
              value: c.value,
              periodicity: c.periodicity,
              name: c.name,
              teamName: c.teamName,
              kpiName: c.kpiName,
              kpiUnit: c.kpiUnit,
              user: {
                email: c.user?.email,
                name: c.user?.name,
              },
            })),
            companyName: data.companyName || process.env.REACT_APP_PLATFORM,
            email: data.user?.email,
            name: data.user?.name,
            life: process.env.REACT_APP_PLATFORM === 'life'
          },
        });

        const result = setupResult.data.setupInstance;

        if (!result.success) {
          if (result.code === 'slug-exists') return alert("This company already exists in our system.");
          if (result.message) return alert(result.message);
        }

        const slug = `${data.companyName || process.env.REACT_APP_PLATFORM}`.toLowerCase().trim().replace(/[^a-z0-9\s]/gi, '').replace(/\s+/g, '-');
        const token = result.token;

        return navigate(`/success/${slug}/${token}`);
      } catch (e) {
        return alert(e);
      }
    }

    navigate("/challenges");
  }

  return (
    <div className="w-full 2xl:w-3/4 m-auto lg:p-4 lg:flex lg:pt-20">
      <div className="p-4 m-4 hidden lg:block">
        <Progression currentStep={2} />
      </div>

      <div className="primary-box flex-1 lg:p-10">
        <div className="flex pt-3 pb-6">
          <h1 className="text-3xl text-title font-bold font-sans flex-1">
            Your account
          </h1>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <FormInput
              label="Your name"
              type="text"
              error={errors.name?.message}
              placeholder="John Doe"
              {...register("name", { required: "Please define your name" })}
            />

            <FormInput
              label="Your email address"
              type="email"
              error={errors.email?.message}
              placeholder="john@doe.co"
              {...register("email", {
                required: "Please enter your email address",
                validate: { checkUserAvailable },
              })}
            />
          </div>

          <div className="justify-between sticky bottom-0 right-0 left-0 py-4 lg:py-0 bg-white flex lg:relative">
            <Button onClick={() => navigate("/assign")} variant="noborder" type="button">
              Back
            </Button>
              
            <Button type="submit" disabled={loading}>{loading ? 'Generating...' : 'Finish setup'}</Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UserInfoPage;
