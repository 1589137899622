import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

import Button from "../components/Button";
import Progression from "../components/Progression";
import persoChallenges from "../libs/persoChallenges";

import usersIcon from '../assets/icons/users.svg';
import arrIcon from '../assets/icons/arr.svg';
import cashIcon from '../assets/icons/cash.svg';
import clvIcon from '../assets/icons/clv.svg';
import demosIcon from '../assets/icons/demos.svg';
import profitIcon from '../assets/icons/profit.svg';
import retentionIcon from '../assets/icons/retention.svg';
import stickyIcon from '../assets/icons/sticky.svg';


const challengesPro = [
  {
    id: 1,
    name: "More users",
    kpiName: "# Users",
    kpiUnit: "number",
    teamName: "Marketing",
    icon: usersIcon,
    value: 100,
    periodicity: 'month',
    periodicities: ['month', 'year'],
  },
  {
    id: 2,
    name: "Increase ARR",
    kpiName: "$ ARR",
    kpiUnit: "currency",
    teamName: "Product",
    icon: arrIcon,
    value: 10000,
    periodicity: 'month',
    periodicities: ['month', 'year'],
  },
  {
    id: 3,
    name: "Improve retention",
    kpiName: "$ retained",
    kpiUnit: "currency",
    teamName: "Customer success",
    icon: retentionIcon,
    value: 120,
    periodicity: 'month',
    periodicities: ['month', 'year'],
  },
  {
    id: 4,
    name: "Increase profit",
    kpiName: "$ of Profit",
    kpiUnit: "currency",
    teamName: "Product",
    icon: profitIcon,
    value: 40,
    periodicity: 'month',
    periodicities: ['month', 'year'],

  },
  {
    id: 5,
    name: "More demos",
    kpiName: "# demos",
    kpiUnit: "number",
    teamName: "Sales",
    icon: demosIcon,
    value: 90,
    periodicity: 'month',
    periodicities: ['month', 'year'],
  },
  {
    id: 6,
    name: "Increase CLV",
    kpiName: "$ per user",
    kpiUnit: "currency",
    teamName: "Product",
    icon: clvIcon,
    value: 30,
    periodicity: 'month',
    periodicities: ['month', 'year'],
  },
  {
    id: 7,
    name: "Improve cash",
    kpiName: "$",
    kpiUnit: "currency",
    teamName: "Product",
    icon: cashIcon,
    value: 3000,
    periodicity: 'month',
    periodicities: ['month', 'year'],

  },
  {
    id: 8,
    name: "Enhance stickiness",
    kpiName: "month",
    kpiUnit: "number",
    teamName: "Product",
    icon: stickyIcon,
    value: 2,
    periodicity: 'month',
    periodicities: ['month', 'year'],
  },
]



const CHALLENGES_LIFE_QUERY = gql`
query LifeChallenges {
  challenges: getLifeChallenges {
    id
    kpiName
    kpiUnit
    name
    actions {
      name
      id
    }
  }
}
`;

function SelectChallengesPage() {
  const [selectedCategories, setSelectedCategories] = React.useState<Challenge[]>([]);
  
  const { data: challengeData } = useQuery(CHALLENGES_LIFE_QUERY)
  const navigate = useNavigate();

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem('formValue') || "{}");
    setSelectedCategories(data.challenges || []);
  }, []);

  const challenges = useMemo(() => {
    if (process.env.REACT_APP_PLATFORM === 'life') {
      const t =  challengeData?.challenges?.map((team: any) => ({
        ...team,
        ...(persoChallenges as any)[team.name],
        teamName: team.name,
      }));

      return t;
    }

    return challengesPro;
  }, [challengeData])


  function categoryClicked(challenge: Challenge) {
    if (selectedCategories.includes(challenge)) {
      setSelectedCategories(selectedCategories.filter((c) => c.id !== challenge.id));
    } else {
      setSelectedCategories([...selectedCategories, challenge]);
    }
  }

  async function sendSelected() {
    if (!selectedCategories.length) return alert("You must select at least one challenge");

    const data = JSON.parse(sessionStorage.getItem('formValue') || "{}");
    data.challenges = selectedCategories;
    sessionStorage.setItem('formValue', JSON.stringify(data));

    navigate('/assign');
  }

  return (
    <div className="w-full 2xl:w-3/4 m-auto lg:p-4 lg:flex lg:pt-20">
      <div className="p-4 m-4 hidden lg:block">
        <Progression currentStep={0} />
      </div>

      <div className="primary-box flex-1 lg:p-10">
        <div className="flex pt-3 pb-6">
          <h1 className="text-3xl text-title font-bold font-sans flex-1">
            
            {((process.env.REACT_APP_PLATFORM === 'life') ? 'Your challenges' : 'Your company\'s challenges')}
          </h1>
        </div>

        <div>
          <div className="lg:grid lg:grid-cols-5 space-y-2 gap-4 pb-6">
            {challenges?.length && challenges.map((challenge: Challenge) => {
              const selected = selectedCategories.find((c) => c.id === challenge.id);
              return (
                <div
                  className={`${
                    selected ? "border-blue-400" : "border-white"
                  } border-4 p-6 px-10 lg:px-6 bg-white w-full shadow-sm rounded-lg items-center justify-between lg:justify-center flex lg:flex-col cursor-pointer`}
                  onClick={() => categoryClicked(challenge)}
                  key={`team-perso${challenge.id}`}
                >
                  <img 
                    src={challenge.icon}
                    alt={challenge.name}
                    className="max-h-12 max-w-12 lg:max-h-28 lg:max-w-28" />
                  <span className="text-lg text-gray-700 font-bold block lg:pt-4 lg:pb-2 text-center pl-2 lg:pl-0">
                    {challenge.name}
                  </span>
                  <span className="text-md text-gray-500 block align-middle text-sm lg:pb-4 text-center pl-2 lg:pl-0">
                    Def. KPI: <div className="inline-block">{challenge.kpiName}</div>
                  </span>
                </div>
              );
            })}
          </div>
          
          <div className="justify-between sticky bottom-0 right-0 left-0 py-4 lg:py-0 bg-white flex lg:relative">
            <div> </div>

            {!selectedCategories.length ? (
              <span>Please select at least one challenge</span>
            ) : (
              <Button onClick={sendSelected}>Set goals</Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectChallengesPage;
