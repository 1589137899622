import { gql, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Creatable from "react-select/creatable";

import Button from "../components/Button";
import FormInput from "../components/FormInput";
import Progression from "../components/Progression";
import Toggle from "../components/Toggle";
import InviteModal from './InviteModal';
import FormSelectInput from '../components/FormSelectInput';
import persoChallenges from "../libs/persoChallenges";
import { useMediaQuery } from 'react-responsive'

const SETUP_MUTATION = gql`
  mutation SetupInstance($challenges: [ChallengeInput!], $name: String!, $companyName: String!, $email: String!, $life: Boolean) {
    setupInstance(challenges: $challenges, name: $name, email: $email, companyName: $companyName, life: $life) {
      code
      created
      id
      message
      token
      slug
      status {
        error
        success
        user {
          id
          email
        }
      }
      success
    }
  }
`;

interface FormValue {
  challenges: {
    value?: number;
    global?: boolean;
    periodicity?: string;
    kpiName: {
      value?: string,
      label?: string,
    };
    user?: any
  }[]
}


function AssignTargetsPage() {
  const navigate = useNavigate();
  const { control, formState: { errors }, register, handleSubmit  } = useForm<FormValue>({ mode: 'all'});

  const { fields, insert } = useFieldArray({
    control,
    name: "challenges",
  });

  const [emailInvite, setEmailInvite] = useState("");
  const isLgScreen = useMediaQuery({ query: '(min-width: 1024px)' })

  const [usersToInvite, setUsersToInvite] = useState<User[]>([]);
  const [challenges, setChallenges] = useState<Challenge[]>();

  const [setupMutation, { loading }] = useMutation(SETUP_MUTATION);
  const isLife = process.env.REACT_APP_PLATFORM === 'life';

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("formValue") || "{}");
    setUsersToInvite(Object.keys(data.users || {}).map((email) => ({ email, name: data.users[email].name, isYou: data.users[email].isYou })));
    setChallenges(data.challenges || []);

    data?.challenges?.map((c: Challenge, i:number) => {
      return insert(i, {
        value: c.value,
        periodicity: c.periodicity,
        kpiName: { label: c.kpiName, value: c.kpiName },
      });
    });
  }, [insert]);

  async function sendForm({ challenges: challengesForm } : FormValue) {
    if (!challenges?.length) return;

    const challenges2 = challenges.map((c, i:number) => {
      return {
        ...c,
        user: challengesForm[i].user,
        periodicity: challengesForm[i].periodicity,
        value: parseFloat(`${challengesForm[i].value}`) || 0,
        kpiName: challengesForm[i].kpiName?.value,
      }
    });

    const formData = JSON.parse(sessionStorage.getItem("formValue") || "{}");
    formData.challenges = challenges2;

    let me = formData.user;
    if (!isLife) {    
      me = challenges2.find((c) => c?.user?.isYou)?.user;
      if(!me) return alert("Please invite yourself in a team.");
    }


    if (process.env.REACT_APP_PLATFORM === 'life') {
      sessionStorage.setItem("formValue", JSON.stringify(formData));
      return navigate('/account');
    }

    try {
      const setupResult = await setupMutation({
        variables: {
          challenges: challenges2.map((c) => ({
            id: c.id,
            value: c.value,
            periodicity: c.periodicity,
            name: c.name,
            teamName: c.teamName,
            kpiName: c.kpiName,
            kpiUnit: c.kpiUnit,
            user: {
              email: c.user?.email,
              name: c.user?.name,
            },
          })),
          companyName: formData.companyName || process.env.REACT_APP_PLATFORM,
          email: me?.email,
          name: me?.name,
          life: process.env.REACT_APP_PLATFORM === 'life'
        },
      });

      const result = setupResult.data.setupInstance;

      if (!result.success) {
        if (result.code === 'slug-exists') return alert("This company already exists in our system.");
        if (result.message) return alert(result.message);
      }

      const slug = `${formData.companyName || process.env.REACT_APP_PLATFORM}`.toLowerCase().trim().replace(/[^a-z0-9\s]/gi, '').replace(/\s+/g, '-');
      const token = result.token;

      return navigate(`/success/${slug}/${token}`);
    } catch (e) {
      console.error(e);
      alert((e as any).message);
    }
  }

  function userInvited() {
    setEmailInvite("");
    const data = JSON.parse(sessionStorage.getItem("formValue") || "{}");
    setUsersToInvite(Object.keys(data.users || {}).map((email) => ({ email, name: data.users[email].name, isYou: data.users[email].isYou })));
  }

  function createOptions(challenge?: Challenge) {
    return [
      { label: challenge?.kpiName, value: challenge?.kpiName },
      ...(Object.values(persoChallenges).find((c) => c.name === challenge?.name)?.alternativeKpi?.map((t) => ({label: t, value: t})) || [])
    ];
  }

  function createPeriodicities(challenge?: Challenge) {
    return (Object.values(persoChallenges).find((c) => c.name === challenge?.name)?.periodicities?.map((t) => ({text: t, value: t})) || [])
  }

  return (
    <div className="w-full 2xl:w-3/4 m-auto lg:p-4 lg:flex lg:pt-20">
      {!!emailInvite?.length && <InviteModal email={emailInvite} onSubmitExternal={userInvited}  />}
      <div className="p-4 m-4 hidden lg:block">
        <Progression currentStep={1} />
      </div>

      <div className="primary-box flex-1 lg:p-10">
        <div className="flex pt-3 pb-6">
          <h1 className="text-3xl text-title font-bold font-sans flex-1">
            Set goals
          </h1>
        </div>

        <form className="w-full flex flex-col" onSubmit={handleSubmit(sendForm)}>
          <table className="flex-1 mx-4 my-6 p-3 table-fixed text-center hidden lg:table">
            <thead className="hidden lg:table-header-group">
              <tr>
                <th className="font-bold text-left">
                  <h3 className="text-lg">Challenge</h3>
                </th>
                <th className="font-bold text-center flex-1">
                  <h3 className="text-lg">Target</h3>
                </th>
                <th className="font-bold text-center">
                  <h3 className="text-lg">Goal timeframe</h3>
                </th>
                {!isLife && <th className="font-bold text-center hidden lg:block">
                  <h3 className="text-lg">Team member</h3>
                </th>}
              </tr>
            </thead>

            <tbody className="p-3 text-left">
              {fields?.map((field, index) => {
                return (
                  <>
                  <tr className="lg:hidden pt-10" key={field.id}>
                    <td>
                      <div className="flex self-center items-center space-x-2">
                        <img
                          src={challenges?.[index]?.icon}
                          alt={challenges?.[index]?.name}
                          className="float-left max-h-10 max-w-10 "
                        />
                        <span>{challenges?.[index]?.name}</span>
                      </div>
                    </td>

                    <td className="text-center">
                      {!isLife && <Controller
                        name={`challenges.${index}.user`}
                        control={control}
                        rules={{
                          required: "You need to assign a user to this target"
                        }}
                        render={({ field: { value, onChange, onBlur } }) => {
                          return (
                            <div>
                              <Creatable
                                value={value}
                                onBlur={onBlur}
                                onChange={onChange}
                                formatOptionLabel={(option: any) => option.name ? `${option.name} (${option.email})` : `Invite via email${option.value ? `: ${option.value}` : ""}`}
                                options={usersToInvite}
                                onCreateOption={(value) => setEmailInvite(value || " ")}
                                placeholder="Select or invite user"
                                allowCreateWhileLoading
                                createOptionPosition="first"
                                isValidNewOption={() => true}
                              />
                              {errors.challenges?.[index]?.user?.message && <p className="text-red-500 text-xs italic">{errors.challenges?.[index]?.user?.message}</p>}
                            </div>
                          );
                        }}
                      />}
                    </td>
                  </tr>

                  <tr key={`${field.id}-2`} className="text-sm">
                    <td className="hidden lg:table-cell">
                      <div className="self-center items-center space-x-2 flex">
                        <img
                          src={challenges?.[index]?.icon}
                          alt={challenges?.[index]?.name}
                          className="float-left max-h-10 max-w-10 "
                        />
                        <span>{challenges?.[index]?.name}</span>
                      </div>
                    </td>
                    <td className="flex items-center space-x-2 pb-20 lg:pb-2">
                      <FormInput
                        nolabel
                        type="number"
                        noMargin
                        error={errors.challenges?.[index]?.value?.message}
                        className="w-20 appearance-none border border-title rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline required:border-red-500"
                        {...register(`challenges.${index}.value`, { required: "A target is required" })}
                      />
                      <div className="flex-1 text-left">
                        <Controller
                          name={`challenges.${index}.kpiName`}
                          control={control}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <Creatable
                              options={createOptions(challenges?.[index])}
                              styles={{ 
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: "#185373",
                                  margin: 0,
                                  marginTop: -3,
                                  padding: 0,
                                  minHeight: 36,
                                }),
                                container: (baseStyles) => ({ ...baseStyles, margin: 0, padding: 0 }),  
                                valueContainer: (baseStyles) => ({ ...baseStyles, margin: 0 }),  
                                indicatorsContainer: (baseStyles) => ({ ...baseStyles, padding: 1, border: 0 }),     
                                dropdownIndicator: (baseStyles) => ({ ...baseStyles, padding: 1 }),
                                indicatorSeparator: (baseStyles) => ({ ...baseStyles, width: 0, height: 0 }),     
                              }}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          )}
                        />
                      </div>
                    </td>
                    <td className="pb-20 lg:pb-2">
                    <Controller
                        name={`challenges.${index}.global`}
                        control={control}
                        render={({ field: { onChange, value } }) =>  (
                        <Toggle
                          id={`perso-toggle-assign-${field.id}-${index}`}
                          afterLabel="Annualy"
                          beforeLabel="Monthly"
                          checked={value}
                          onChange={onChange}
                        />
                        )} />
                    </td>
                    <td className="text-center hidden lg:block">
                      {!isLife && <Controller
                        name={`challenges.${index}.user`}
                        control={control}
                        rules={{
                          required: "You need to assign a user to this target"
                        }}
                        render={({ field: { value, onChange, onBlur } }) => {
                          return (
                            <div>
                              <Creatable
                                value={value}
                                onBlur={onBlur}
                                onChange={onChange}
                                formatOptionLabel={(option: any) => option.name ? `${option.name} (${option.email})` : `Invite via email${option.value ? `: ${option.value}` : ""}`}
                                options={usersToInvite}
                                onCreateOption={(value) => setEmailInvite(value || " ")}
                                placeholder="Select or invite user"
                                allowCreateWhileLoading
                                createOptionPosition="first"
                                isValidNewOption={() => true}
                              />
                              {errors.challenges?.[index]?.user?.message && <p className="text-red-500 text-xs italic">{errors.challenges?.[index]?.user?.message}</p>}
                            </div>
                          );
                        }}
                      />}
                    </td>
                  </tr>
                  </>
                );
              })}
            </tbody>
          </table>


          <div className="flex-1 my-6 text-center lg:hidden">
              {fields?.map((field, index) => {
                return (
                  <div className="mx-3 px-1 mb-8 pb-8 border-b border-gray-200 last:border-0" key={field.id}>
                    <div className="flex self-center items-center space-x-2 pb-3">
                      <img
                        src={challenges?.[index]?.icon}
                        alt={challenges?.[index]?.name}
                        className="float-left max-h-10 max-w-10 "
                      />
                      <span className="font-medium">{challenges?.[index]?.name}</span>
                    </div>

                    {!isLgScreen && <div className="flex flex-wrap items-baseline space-x-2 text-sm">
                      {(!isLife || false) ? <Controller
                        name={`challenges.${index}.user`}
                        control={control}
                        rules={{ required: "You need to assign a user to this target" }}
                        render={({ field: { value, onChange, onBlur } }) => {
                          return (
                            <div>
                              <Creatable
                                value={value}
                                onBlur={onBlur}
                                onChange={onChange}
                                formatOptionLabel={(option: any) => option.name ? `${option.name} (${option.email})` : `Invite via email${option.value ? `: ${option.value}` : ""}`}
                                options={usersToInvite}
                                onCreateOption={(value) => setEmailInvite(value || " ")}
                                placeholder="Select or invite user"
                                allowCreateWhileLoading
                                createOptionPosition="first"
                                isValidNewOption={() => true}
                                className="inline-block"
                              />
                              {errors.challenges?.[index]?.user?.message && <p className="text-red-500 text-xs italic">{errors.challenges?.[index]?.user?.message}</p>}
                            </div>
                          );
                        }}
                      /> : <span>I want to achieve</span>}

                      <FormInput
                        nolabel
                        type="number"
                        noMargin
                        error={errors.challenges?.[index]?.value?.message}
                        className="w-20 appearance-none border-b border-title py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline required:border-red-500"
                        {...register(`challenges.${index}.value`, { required: "A target is required" })}
                      />

                      <Controller
                        name={`challenges.${index}.kpiName`}
                        control={control}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <Creatable
                            options={createOptions(challenges?.[index])}
                            styles={{ 
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: 'rgba(24, 83, 115, 1)',
                                borderRadius: 0,
                                borderWidth: 0,
                                borderBottomWidth: 1,
                                margin: 0,
                                padding: 0,
                                minHeight: 36,
                              }),
                              container: (baseStyles) => ({ ...baseStyles, margin: 0, padding: 0 }),  
                              valueContainer: (baseStyles) => ({ ...baseStyles, margin: 0 }),  
                              indicatorsContainer: (baseStyles) => ({ ...baseStyles, padding: 1, border: 0 }),     
                              dropdownIndicator: (baseStyles) => ({ ...baseStyles, padding: 1 }),
                              indicatorSeparator: (baseStyles) => ({ ...baseStyles, width: 0, height: 0 }),     
                            }}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                          />
                        )}
                      />

                      <div className="flex items-baseline space-x-2">
                        <span> per </span>
                        <FormSelectInput
                          options={createPeriodicities(challenges?.[index])}
                          style={{ borderWidth: 0, borderRadius: 0, borderBottomWidth: 1 }}
                          {...register(`challenges.${index}.periodicity`)}
                        />
                        <span>.</span>
                      </div>
                    </div>}
                  </div>
                );
              })}
          </div>

          

          <div className="justify-between sticky bottom-0 right-0 left-0 py-4 lg:py-0 bg-white flex lg:relative">
              <Button onClick={() => navigate("/challenges")} variant="noborder">
                Back
              </Button>

            <Button type="submit" disabled={loading}>{loading ? 'Generating...' : 'Validate targets'}</Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AssignTargetsPage;
