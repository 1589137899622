import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
// import { hotjar } from 'react-hotjar';

const buildType = process.env.REACT_APP_BUILD_TYPE;


if (buildType === 'prod') {
//  hotjar.initialize(3024936, 6);
  ReactGA.initialize("G-BDJY497CP4");
  ReactGA.set({ dimension1: `setup-${process.env.REACT_APP_PLATFORM === 'life' ? 'life' : 'app' }`})
}

const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (buildType !== 'prod') {
      console.log("Page change "+location.pathname);
    } else {
//      hotjar.stateChange(location.pathname);
      ReactGA.send("pageview");
    }
  }, [location]);
};

export default useAnalytics;
