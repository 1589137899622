import { gql, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Button from "../components/Button";
import FormInput from "../components/FormInput";
import Modal from "../components/Modal";

import getCompanyName from "../libs/getCompanyName";

const USER_EXISTS_QUERY = gql`
  query checkUserExists($email: String) {
    checkUserExists(email: $email)
  }
`;

const COMPANY_EXISTS_QUERY = gql`
  query checkCompanyExists($name: String) {
    checkCompanyExists(name: $name)
  }
`;

type FormValues = {
  name: string;
  email: string;
  isYou: boolean;
  companyName: string;
};

function InviteModal({email, onSubmitExternal} : {email: string, onSubmitExternal: Function}) {
  const [needYou, setNeedYou] = React.useState<boolean>(false);
  const [needCompany, setNeedCompany] = React.useState<boolean>(false);

  const { refetch: checkUserExists } = useQuery(USER_EXISTS_QUERY);
  const { refetch: checkCompanyExists } = useQuery(COMPANY_EXISTS_QUERY);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormValues>({ defaultValues: { email }, mode: 'onChange' });

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("formValue") || "{}");
    setNeedYou(!data?.users?.length || !Object.values(data?.users).find((u: any) => u.isYou));
    setNeedCompany(!data?.companyName);
  }, []);

  useEffect(() => {
    const email = watch('email');
    const data = JSON.parse(sessionStorage.getItem("formValue") || "{}");
    if (!data?.companyName) {
      const slug = getCompanyName(email);
      if (slug) setValue("companyName", slug, { shouldValidate: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('email'), checkCompanyExists]);

  function cleanSlug(input: string) {
    return `${input}`.trim().toLowerCase().replace(/[^a-z0-9]/g, "-")
  }

  // Checks if the company subdomain exists
  async function checkCompanyAvailable(name: string) {
    if (!checkCompanyExists) return undefined;

    if (!name) return undefined;
    const exists = (await checkCompanyExists({ name }))?.data?.checkCompanyExists;
    return exists ? "This company domain already exists. Please choose another subdomain." : undefined;
  }

  // Check if this user already exists in our DB
  async function checkUserAvailable(email: string) {
    if (!checkUserExists) return undefined;

    if (!email) return undefined;
    const res = (await checkUserExists({ email }));
    const exists = res?.data?.checkUserExists;
    return exists ? "This user already exists in Yood. Please use another email address." : undefined;
  }

  function onSubmit(data: FormValues) {
    const fromStorage = JSON.parse(sessionStorage.getItem('formValue') || "{}");

    if (!fromStorage.users) fromStorage.users = {};
    fromStorage.users[data.email] = {name: data.name, isYou: data.isYou };
    if (!fromStorage.companyName) fromStorage.companyName = cleanSlug(data.companyName);

    sessionStorage.setItem('formValue', JSON.stringify(fromStorage));
    if (onSubmitExternal) onSubmitExternal(true);
  }

  return (
    <Modal title="Invite user" autoHeight className="max-w-lg" closeFunction={() => onSubmitExternal(false)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label="Business email of team member"
          type="email"
          error={errors.email?.message}
          placeholder="john@doe.co"
          {...register("email", { required: "Please enter your email address", validate: { checkUserAvailable } })}
        />

        <FormInput
          label="Name of team member"
          type="text"
          error={errors.name?.message}
          placeholder="John Doe"
          {...register("name", { required: "Please define your name" })}
        />

        {needYou && <FormInput
          label="Is it you ?"
          type="checkbox"
          error={errors.isYou?.message}
          {...register("isYou")}
        />}

        {!!(watch('email') && needCompany) && <FormInput
          label={`Company subdomain (${cleanSlug(watch('companyName')) || 'xxx'}.yood.co)`}
          type="text"
          placeholder="acme"
          error={errors.companyName?.message}
          {...register("companyName", { required: "Please choose a domain", validate: { checkCompanyAvailable } })}
        />}

        <div className="flex justify-end space-x-6 mt-10">
          <Button
            type="button"
            className="inline-block"
            disabled={!!errors.name || !!errors.email}
            variant="outlined"
            onClick={() => onSubmitExternal(false)}
          >
            Cancel
          </Button>
          <Button
            className="inline-block"
            disabled={!!errors.name || !!errors.email}
          >
            Invite
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default InviteModal;
