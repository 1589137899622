import './Toggle.css'

interface ToggleProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  beforeLabel?: string;
  afterLabel?: string;
}

export default function Toggle(props: ToggleProps) {
  const { id, beforeLabel, afterLabel, type, className, ...rest } = props;
  return (
    <div className="flex items-center justify-center h-full w-full">
      <label htmlFor={id} className="flex items-center cursor-pointer">
        {!!beforeLabel && (
          <div className="mr-3 font-medium">{beforeLabel}</div>
        )}
        <div className="relative">
          <input type="checkbox" id={id} className="sr-only" {...rest} />
          <div className="block bg-gray-400 w-12 h-7 rounded-full"></div>
          <div className="dot absolute left-1 top-1 bg-white w-5 h-5 rounded-full transition"></div>
        </div>
        {!!afterLabel && (
          <div className="ml-3 font-medium">{afterLabel}</div>
        )}
      </label>
    </div>
  );
};
