const publicEmailDomains = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "outlook.com",
  "aol.com",
  "icloud.com",
  "mail.com",
  "protonmail.com",
  "zoho.com",
  "yandex.com",
  "gmx.com",
  "rediffmail.com",
  "live.com",
  "mailinator.com",
  "inbox.com",
  "fastmail.com",
  "tutanota.com",
  "rocketmail.com",
  "icloud.com",
  "outlook.in",
  "ymail.com",
  "sbcglobal.net",
  "att.net",
  "cox.net",
  "verizon.net",
  "comcast.net",
  "bellsouth.net",
  "shaw.ca",
  "earthlink.net",
  "me.com",
  "mac.com",
  "juno.com",
  "btinternet.com",
  "optonline.net",
  "ntlworld.com",
  "web.de",
  "gmx.net",
  "telus.net",
  "rogers.com",
  "sympatico.ca",
  "libero.it",
  "alice.it",
  "tin.it",
  "tiscali.it",
  "eircom.net",
  "blueyonder.co.uk",
  "virginmedia.com",
  "sky.com",
  "talktalk.net",
  "ntlworld.com",
  "orange.fr",
  "free.fr",
  "sfr.fr",
  "neuf.fr",
  "laposte.net",
  "bbox.fr",
  "numericable.fr",
  "wanadoo.fr",
  "hotmail.fr",
  "yahoo.fr",
  "hotmail.co.uk",
  "yahoo.co.uk",
  "btinternet.com",
  "aol.co.uk",
  "sky.com",
  "blueyonder.co.uk",
  "talk21.com",
  "btopenworld.com",
  "googlemail.com",
  "virgin.net",
  "ntlworld.com",
  "msn.com",
  "hotmail.de",
  "hotmail.it",
  "yahoo.de",
  "yahoo.it",
  "aol.de",
  "aol.it",
  "googlemail.de",
  "googlemail.it",
]; // Add more public email domains as needed

export default function getCompanyName(email: string): string | null {
  if (!email?.length) return null;

  const domain = email.split("@")[1];
  if (domain && !publicEmailDomains.includes(domain)) {
    const companyName = domain.split(".")[0]; // Assuming the company name is the first part before the dot
    return companyName;
  }

  return null; // Return null if it's a public email provider
}
