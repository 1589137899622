import { Routes, Route, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import logo from "./assets/transparent.png";

import useAnalytics from "./hooks/analytics";
import SelectChallengesPage from "./pages/SelectChallengesPage";
import AssignTargetsPage from "./pages/AssignTargetsPage";
import SuccessPage from "./pages/SuccessPage";
import UserInfoPage from "./pages/UserInfoPage";
import RedirectToLife from "./pages/RedirectToLife";


function App() {
  useAnalytics();

  return (
    <div className="bg-gray-50 text-gray-500 absolute left-0 top-0 right-0 min-h-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Setup your Yood platform</title>
        <link rel="canonical" href="https://setup.yood.co" />
      </Helmet>

      <div className="text-title font-bold flex items-center justify-between m-auto p-5 pb-0 w-full 2xl:w-3/4">
            <img
              src={logo}
              className="h-14 w-14 lg:h-16 lg:w-16 rounded-full mr-4"
              alt="Yood Logo"
            />

            <h1 className="text-xl mr-4">Yood Life</h1>
        </div>

      <Routes>
        <Route path="/" element={<RedirectToLife />} />

        <Route path="/challenges" element={<SelectChallengesPage />} />
        <Route path="/assign" element={<AssignTargetsPage />} />
        <Route path="/account" element={<UserInfoPage />} />

        <Route path="/success/:slug/:token" element={<SuccessPage />} />

        <Route path="*" element={<Navigate to="/" replace />} /> 
      </Routes>     
    </div>
  );
}

export default App;
